import { ClassNameValue, twMerge } from "tailwind-merge";

// The cn function accepts any number of class name arguments using the spread syntax. It passes these arguments to clsx to merge them into a single string.
// It then passes the merged class names to twMerge, which is a function from tailwind-merge that intelligently merges Tailwind CSS classes. This avoids duplicating classes that are already present.
// For example:
// cn('text-red', 'bg-blue')
// --> 'text-red bg-blue'
// cn('text-red', 'text-blue')
// --> 'text-blue'
// cn(['text-red', 'text-blue'])
// --> 'text-blue'
export function cn(...inputs: ClassNameValue[]) {
  return twMerge(inputs);
}
