"use client";

import { useAnalytics } from "@everfund/event-detective";
import { createContext } from "react";
import { useEffect, useMemo } from "react";

import useSingleUser from "~/requests/queries/singleUser";
import { User } from "~/services/users/single";

export type Context = {
  isFetching: boolean;
  user?: User;
};

export const UserContext = createContext<Context>({
  isFetching: false,
  user: undefined,
});

type ProviderProps = {
  children: React.ReactNode;
  id?: null | string;
};

export function UserProvider({ children, id }: ProviderProps) {
  const { data: user, isFetching } = useSingleUser(id!);
  const { identify } = useAnalytics();

  const contextValue = useMemo(
    () => ({
      isFetching,
      user: user?.id ? user : undefined,
    }),
    [user, isFetching],
  );

  useEffect(() => {
    if (user) {
      identify({
        traits: {
          email: user.email,
          firstName: user.firstName,
          language: user.language,
          lastName: user.lastName,
          liveMode: user.liveMode,
          timeZone: user.timeZone,
        },
        userId: user.id,
      });
    }
  }, [identify, user]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
