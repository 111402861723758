"use client";

import {
  faCheckCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";
import { Toast, toast } from "react-hot-toast";

export type ToastMethods = typeof toast;

type MainToastProps = {
  hideDismissBtn?: boolean;
  hideIcon?: boolean | undefined;
  icon?: ReactElement;
  subTitle?: string;
  t: Toast;
  title: string;
  toast: ToastMethods;
  type?: "blank" | "error" | "loading" | "success";
};

const MainToast = (props: MainToastProps) => {
  return (
    <Transition
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={props.t.visible}
    >
      <div
        className="pointer-events-auto flex w-max divide-x divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black/5 dark:divide-nord-3 dark:bg-nord-2"
        id="toast"
      >
        <div className="flex w-full flex-row items-center gap-4 p-4">
          {!props.hideIcon && (
            <div className="flex items-center">
              {props.icon ? (
                <> {props.icon} </>
              ) : props.type === "success" ? (
                <FontAwesomeIcon
                  className="h-5 w-auto text-green-400"
                  icon={faCheckCircle}
                />
              ) : props.type === "error" ? (
                <FontAwesomeIcon className="h-5 w-auto" icon={faTimesCircle} />
              ) : props.type === "loading" ? (
                <FontAwesomeIcon className="h-5 w-auto" icon={faSpinner} />
              ) : (
                <></>
              )}
            </div>
          )}

          <div className="w-full">
            <p className="text-sm font-medium text-primary">{props.title}</p>
            {props.subTitle && (
              <p className="mt-1 text-sm text-secondary">{props.subTitle}</p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="flex w-max flex-col divide-y divide-gray-200 dark:divide-nord-3">
            {!props.hideDismissBtn && (
              <DismissButton t={props.t} toast={props.toast} />
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

const DismissButton = ({ t, toast }: Pick<MainToastProps, "t" | "toast">) => {
  return (
    <div className="flex h-0 flex-1 text-sm font-medium text-secondary">
      <button
        className="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={() => toast.dismiss(t.id)}
      >
        Dismiss
      </button>
    </div>
  );
};

export default MainToast;
