"use client";

import { ReactElement } from "react";
import { useSeparator } from "react-aria";

export interface SeparatorOptions {
  /**
   * If `true`, the button will show a spinner.
   */
  orientation?: "horizontal" | "vertical";
  padding?: boolean;
}

interface SeparatorProps extends SeparatorOptions {}

const Separator = (props: SeparatorOptions) => {
  const { separatorProps } = useSeparator(props);
  return (
    <div
      {...separatorProps}
      className="not-prose bg-nord-4 dark:bg-nord-3"
      style={{
        height: props.orientation === "vertical" ? "100%" : "1px",
        margin: props.orientation === "vertical" ? "0 5px" : "5px 0",
        width: props.orientation === "vertical" ? "1px" : "100%",
      }}
    />
  );
};

const SeparatorWrapper = (props: SeparatorProps): ReactElement => {
  const { orientation = "horizontal", padding } = props;
  if (padding) {
    return (
      <div className="not-prose py-5">
        <Separator orientation={orientation} />
      </div>
    );
  }

  return <Separator orientation={orientation} />;
};

export default SeparatorWrapper;
