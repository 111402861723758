import { useQuery } from "@tanstack/react-query";

import { User } from "~/services/users/single";

export const GET_USER_KEY = "user";

const useSingleUser = (id: string) =>
  useQuery<unknown, unknown, null | User>({
    enabled: Boolean(id),
    queryFn: async () => (await fetch(`/api/users/${id}`)).json(),
    queryKey: [GET_USER_KEY],
  });

export default useSingleUser;
